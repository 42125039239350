<header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a routerLink="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <img src="../../../assets/img/logo.png" width="150" height="31" alt="logo">
    </a>
    <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
      <a routerLink="/about" class="mr-5 hover:text-gray-900">About</a>
      <a routerLink="/contact" class="mr-5 hover:text-gray-900">Contact</a>
    </nav>
  </div>
</header>
