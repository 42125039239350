<div class="min-h-screen flex flex-col justify-between">

  <div>
    <app-header></app-header>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>

</div>
