import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, AppRoutingModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  year: number;

  constructor() {
    this.year = new Date().getFullYear();
  }
}
